import React from "react"
import SEO from "../components/seo"
import Home from "./home/home"
import '../styles/general.scss';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Home/>
  </>
)

export default IndexPage
